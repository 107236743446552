import "../styles/footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="content">
        <div className="sociales">
          <p className="title">Social</p>
          <a
            href="https://www.instagram.com/power_on_gym_chojna/"
            target="_blank"
            rel="noreferrer">
            Instagram
          </a>
          <a
            href="https://www.facebook.com/@powerongymchojna?locale=pl_PL"
            target="_blank"
            rel="noreferrer">
            Facebook
          </a>
        </div>
        <div className="content-Contact">
          <p className="title">Kontakt</p>
          <p>
            Telefon:
            <br />
            500 785 241
          </p>
          <p>
            email:
            <br />
            Poweronchojna@gmail.com
          </p>
        </div>
        <div className="times">
          <p className="title">Godziny otwarcia</p>
          <p>pon - pt 9:00 - 21:00</p>
          <p>Sobota 10:00 - 18:00</p>
          <p>Niedziela 10:00 - 18:00</p>
        </div>
      </div>
      <div className="policy">
        <p>wszelkie prawa</p>
        <p>wszelkie prawa</p>
        <p>wszelkie prawa</p>
      </div>
    </div>
  );
}

export default Footer;
