import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress } from "@mui/material";

import "../../styles/tariff.css";

const Tariff = () => {
  const [TariffData, setTariffData] = useState([]);
  const [load, setLoad] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get("http://localhost:5000/api/TariffAll");
        const response = await axios.get(
          "https://power-on-gym-7kdm.vercel.app/api/TariffAll"
        );
        setTariffData(response.data);
        setLoad(false);
      } catch (error) {
        console.error("Error fetching tariff data:", error);
      }
    };
    fetchData();
  }, [setTariffData]);

  const Load = () => {
    document.querySelector(".footer").style.display = load ? "none" : "block";

    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "grey",
          overflow: "",
          zIndex: "1000",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Typography variant="h4">Ładowanie danych</Typography>
        <Box sx={{ display: "flex", marginTop: "50px" }}>
          <CircularProgress color="black" />
        </Box>
      </Box>
    );
  };

  const Teriffcolumn = () => {
    return TariffData.map((data) => {
      return (
        <div
          className={`column ${data.name === "4 wejscia" || data.name === "8 wejść" ? "sub" : ""}`}
          key={data.id}>
          <div className="title">{data.name}</div>
          <div className="price">
            {data.name === "karnet fitness" ? "" : data.price}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {load ? (
        <Load />
      ) : (
        <div className="tariff">
          <div className="tariff_title">
            <p>cennik</p>
          </div>
          <div className="Tarif_table">
            <Teriffcolumn />
          </div>
        </div>
      )}
    </>
  );
};

export default Tariff;
