import "../styles/nav.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  Tabs,
  Tab,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function Nav(isLoading) {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState("home");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Navigate = useNavigate();

  useEffect(() => {
    setActive(false);
  }, [Navigate]);

  return (
    <Box>
      <AppBar
        position="fixed"
        top={0}
        sx={{ backgroundColor: "white", zIndex: "99", width: "100vw" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <IconButton
            onClick={() => setActive(!active)}
            size="large"
            edge="start"
            color="black"
            aria-label="menu"
            sx={{ ml: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography>
            <img
              src="/img/logo_black.png"
              alt="logo"
              style={{ width: "150px", height: "auto", display: "block" }}
              onClick={() => {
                Navigate("/");
                setValue("home");
              }}
            />
          </Typography>
        </Toolbar>
      </AppBar>
      <Slide in={active} direction="down" timeout={300}>
        <Box
          sx={{
            position: "fixed",
            top: { xs: "50px", md: "60px" },
            left: 0,
            right: 0,
            height: "10vh",
            backgroundColor: "#f0f0f0",
            zIndex: 98,
            boxShadow: 1,
          }}>
          <Typography
            variant="h6"
            sx={{
              padding: "16px",
              height: "100%",
              color: "#333",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
              variant="scrollable"
              scrollButtons="auto">
              <Tab value="home" label="Home" onClick={() => Navigate("/")} />
              <Tab
                value="O nas"
                label="O nas"
                onClick={() => Navigate("/o-nas")}
              />
              <Tab
                value="Grafik zajęć"
                label="Grafik zajęć"
                onClick={() => Navigate("/timetable")}
              />
              <Tab
                value="Cennik"
                label="Cennik"
                onClick={() => Navigate("/cennik")}
              />
              <Tab
                value="Wydarzenia"
                label="Wydarzenia"
                onClick={() => Navigate("/wydarzenia")}
              />
              <Tab
                value="Oferta"
                label="Oferta"
                onClick={() => Navigate("/oferta")}
              />
              <Tab
                value="Kontakt"
                label="Kontakt"
                onClick={() => Navigate("/kontakt")}
              />
            </Tabs>
          </Typography>
        </Box>
      </Slide>
    </Box>
  );
}

export default Nav;
