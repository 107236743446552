import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const LoaderWrapper = ({ children, setIsLoading, setLoadingPercentage }) => {
  const [imagesLoading, setImagesLoading] = useState([]);

  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
  }, [location, setIsLoading]);

  const collectImages = () => {
    const images = Array.from(document.images).map((img) => ({
      name: img.src,
      loaded: img.complete,
    }));

    const elements = Array.from(document.querySelectorAll("*"));
    const backgroundImages = elements
      .map((el) => {
        const bgImage = window
          .getComputedStyle(el)
          .getPropertyValue("background-image");
        const match = bgImage.match(/url\("?(.*?)"?\)/);
        return match ? { name: match[1], loaded: false } : null;
      })
      .filter(Boolean);

    setImagesLoading([...images, ...backgroundImages]);
  };

  useEffect(() => {
    const handleLoad = () => {
      collectImages();
    };

    window.onload = handleLoad;

    if ("requestIdleCallback" in window) {
      requestIdleCallback(() => {
        collectImages();
      });
    } else {
      setTimeout(() => collectImages(), 200);
    }

    return () => {
      window.onload = null;
    };
  }, [location]);

  useEffect(() => {
    const allImagesLoaded =
      imagesLoading.length > 0 && imagesLoading.every((img) => img.loaded);

    const loadedImages = imagesLoading.filter((img) => img.loaded).length;

    const percentage = Math.floor((loadedImages / imagesLoading.length) * 100);
    setLoadingPercentage(percentage);

    if (allImagesLoaded) {
      setIsLoading(false);
    }
  }, [imagesLoading, setIsLoading, setLoadingPercentage]);

  useEffect(() => {
    imagesLoading.forEach((img) => {
      if (img.name && !img.loaded) {
        const image = new Image();
        image.src = img.name;
        image.onload = () => {
          setImagesLoading((prevImages) =>
            prevImages.map((prevImg) =>
              prevImg.name === img.name ? { ...prevImg, loaded: true } : prevImg
            )
          );
        };
      }
    });
  }, [imagesLoading]);

  return <>{children}</>;
};
export default LoaderWrapper;

// <>
//   {isLoading && (
//     <div>
//       {/* Wyświetlanie procentu ładowania */}
//       <div>Ładowanie: {loadingPercentage}%</div>
//       <div>
//         <progress value={loadingPercentage} max="100"></progress>
//       </div>
//     </div>
//   )}
//   {children}
// </>
