import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

const ConfigTariff = () => {
  const [TariffData, setTariffData] = useState([]);

  useEffect(() => {
    axios
      // .get("https://localhost:5000/api/TariffAll")
      .get("https://power-on-gym-7kdm.vercel.app/api/TariffAll")
      .then((response) => {
        setTariffData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching tariff data:", error);
      });
  }, [setTariffData]);

  const schema = yup.object().shape({
    id: yup.string().required("Wybór taryfy jest wymagany"),
    price: yup
      .number()
      .required("Cena jest wymagana")
      .min(1, "Cena musi być większa od 0"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: "",
      price: 0,
    },
  });

  const onSubmit = (data) => {
    const updatedTariff = { price: data.price };
    const confirmed = window.confirm("Zmienić cenę?");
    if (!confirmed) {
      return;
    }
    axios
      .put(
        `https://power-on-gym-7kdm.vercel.app/api/tariffChange/${data.id}`,
        updatedTariff,
        {
          // .put(`http://localhost:5000/api/tariffChange/${data.id}`, updatedTariff, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        axios
          // .get("http://localhost:5000/api/TariffAll")
          .get("https://power-on-gym-7kdm.vercel.app/api/TariffAll")
          .then((res) => setTariffData(res.data));
      })
      .catch((error) => {
        console.error("Error updating tariff:", error);
      });
  };

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" noValidate>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <CardContent>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel id="tariff-select-label">Nazwa</InputLabel>
            <Select
              labelId="tariff-select-label"
              id="tariff-select"
              value={watch("id")}
              {...register("id")}
              onChange={(e) => setValue("id", e.target.value)}
              error={!!errors.id}
              MenuProps={{
                PaperProps: {
                  "aria-hidden": false,
                  tabIndex: -1,
                },
              }}>
              {TariffData.map((tariff) => (
                <MenuItem key={tariff.id} value={tariff.id}>
                  {tariff.name}
                </MenuItem>
              ))}
            </Select>
            {errors.id && <p style={{ color: "red" }}>{errors.id.message}</p>}
          </FormControl>
          <TextField
            fullWidth
            id="outlined-price"
            label="Nowa cena"
            type="number"
            {...register("price")}
            error={!!errors.price}
            helperText={errors.price?.message}
          />
        </CardContent>

        <CardActions>
          <Button
            size="small"
            type="submit"
            variant="contained"
            color="primary">
            Zmień taryfę
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ConfigTariff;
