import "../../../styles/poledance.css";

function PoleDance() {
  return (
    <div className="PoleDanceGallery">
      <div className="title">
        <p>Sala do pole Dance</p>
      </div>
      <div className="gallery">
        <div className="photo">
          <img
            src={process.env.PUBLIC_URL + "/images/poledance/poledance_1.jpg"}
            alt=""
          />
        </div>
        <div className="photo">
          <img
            src={process.env.PUBLIC_URL + "/images/poledance/poledance_2.jpg"}
            alt=""
          />
        </div>
        <div className="photo">
          <img
            src={process.env.PUBLIC_URL + "/images/poledance/poledance_3.jpg"}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default PoleDance;
