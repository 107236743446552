import "../../../styles/layout_shop.css";

function LayoutShop() {
  return (
    <div className="layoutShop">
      <div className="title">
        <p>Sklepik</p>
      </div>
      <div className="content">
        <p>
          Zapraszamy do sklepiku przy recepcji. Znajdziesz tam sporo
          asortymentu, który wspomoże efekty twojego treningu.
        </p>
      </div>
      <div className="btn">
        <p className="Hover">Sprawdz naszą ofertę </p>
      </div>
    </div>
  );
}

export default LayoutShop;
