import React, { useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import emailjs from "@emailjs/browser";

import "../../styles/Contact.css";

const Contact = () => {
  const { handleSubmit, control, reset } = useForm();
  const form = useRef();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const onSubmit = (data) => {
    console.log(data);
    emailjs
      .sendForm("service_k28heer", "template_39kt57k", form.current, {
        publicKey: "1xYn4ZEuM6NgAhwfY",
      })
      .then(
        () => {
          setAlertMessage("Wiadomość została wysłana pomyślnie!");
          setAlertSeverity("success");
          setOpenSnackbar(true);
          reset();
        },
        (err) => {
          console.log(err);
          setAlertMessage("Wystąpił problem z wysłaniem wiadomości.");
          setAlertSeverity("error");
          setOpenSnackbar(true);
        }
      );
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="Contact">
      <Typography
        variant="h2"
        gutterBottom
        marginTop={"50px"}
        marginLeft={"20px"}>
        Kontakt
      </Typography>
      <div className="container">
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1058.9255079700106!2d14.445869335481008!3d52.963004660183856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470759daf0c5099d%3A0xe29b2359b2bad20d!2sPower%20On%20Gym!5e1!3m2!1spl!2spl!4v1731776933410!5m2!1spl!2spl"
            style={{ border: 0 }}
            loading="lazy"
            title="Power On Gym Location"></iframe>
        </div>
        <Typography
          variant="h2"
          gutterBottom
          marginTop={"50px"}
          marginLeft={"20px"}>
          Masz pytanie?
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} ref={form}>
          <Box
            sx={{
              width: "80%",
              margin: "100px auto",
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={2}
              flexDirection={{ xs: "column", md: "row" }}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: "Imię jest wymagane" }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    sx={{ width: { sx: "100%", md: "45%" } }}
                    label="Imię"
                    variant="outlined"
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Email jest wymagany",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Wprowadź poprawny adres email",
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    sx={{ width: { sx: "100%", md: "45%" } }}
                    {...field}
                    label="Email"
                    variant="outlined"
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Box>
            <Controller
              name="subject"
              control={control}
              defaultValue=""
              rules={{ required: "Temat jest wymagany" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Temat"
                  variant="outlined"
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
            <Controller
              name="question"
              control={control}
              defaultValue=""
              rules={{ required: "Pytanie jest wymagane" }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Pytanie"
                  name="question"
                  variant="outlined"
                  multiline
                  rows={4}
                  error={!!error}
                  helperText={error ? error.message : ""}
                />
              )}
            />
            <Controller
              name="acces"
              control={control}
              defaultValue={false}
              rules={{ required: "to pole jest wymagane" }}
              render={({ field, fieldState: { error } }) => (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: { xs: "10px", md: "14px" } }}>
                        Wyrażam zgodę na przetwarzanie moich danych osobowych
                        podanych w powyższym formularzu w celach marketingowych
                        i kontaktowych przez Power On gym
                      </Typography>
                    }
                  />
                  {error && (
                    <Typography variant="body2" color="error">
                      {error.message}
                    </Typography>
                  )}
                </Box>
              )}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ width: "15%", margin: "0 auto" }}>
              Wyślij
            </Button>
          </Box>
        </form>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Contact;
