import React from "react";
import { Typography } from "@mui/material";

import LayoutInfo from "./SubRoutes/layout_Info";

const Offert = () => {
  return (
    <>
      <Typography
        variant={"h2"}
        gutterBottom
        marginTop={"150px"}
        marginBottom={"50px"}
        marginLeft={"20px"}>
        Oferta
      </Typography>
      <LayoutInfo />
    </>
  );
};
export default Offert;
