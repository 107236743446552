import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, TextField, Button, Grid2 as Grid } from "@mui/material";
import axios from "axios";

const schema = yup.object().shape({
  title: yup.string().required("Tytuł jest wymagany"),
  month: yup.string().required("Miesiąc jest wymagany"),
  day: yup.string().required("Dzień jest wymagany"),
  dayNr: yup
    .number()
    .required("day number is required")
    .min(1, "dzień musi być większy niż 0"),
  timeFrom: yup.string(),
  timeTo: yup.string(),
  description: yup.string().required("Description is required"),
});

const ConfigEvent = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const dataToSend = {
      id: new Date().getTime(),
      ...data,
    };

    axios
      // .post("http://localhost:5000/api/event", dataToSend, {
      .post("https://power-on-gym-7kdm.vercel.app/api/event", dataToSend, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {})
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", padding: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Title"
                  variant="outlined"
                  fullWidth
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="month"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Month"
                  variant="outlined"
                  fullWidth
                  error={!!errors.month}
                  helperText={errors.month?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="day"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Day"
                  variant="outlined"
                  fullWidth
                  error={!!errors.day}
                  helperText={errors.day?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="dayNr"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Day Number"
                  type="number"
                  variant="outlined"
                  fullWidth
                  error={!!errors.dayNr}
                  helperText={errors.dayNr?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="timeFrom"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Start Time"
                  type="time"
                  variant="outlined"
                  fullWidth
                  error={!!errors.timeFrom}
                  helperText={errors.timeFrom?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="timeTo"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="End Time"
                  type="time"
                  variant="outlined"
                  fullWidth
                  error={!!errors.timeTo}
                  helperText={errors.timeTo?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ConfigEvent;
