import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TimeTable from "../../routes/time_table";

const ConfigTimeTable = (props) => {
  const { setclassesData, classesData, hours, daysOfWeek } = props;

  const [day, setDay] = useState("");
  const [time, setTime] = useState("");

  const token = localStorage.getItem("token");

  const schema = yup.object().shape({
    title: yup.string().required("Login is required"),
    trener: yup.string().required("Password is required"),
    date: yup.string().required("Password is required"),
    time: yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      title: "",
      trener: "",
      date: "",
      time: "",
    },
  });

  const uploadData = async (data) => {
    const newClass = {
      ...data,
      id: new Date().getTime(),
    };

    try {
      const response = await fetch(
        "https://power-on-gym-7kdm.vercel.app/api/post",
        // "http://localhost:5000/api/post",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newClass),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setclassesData(result.receivedData);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  const handleDelete = async (classId) => {
    const confirmed = window.confirm("Czy na pewno chcesz usunąć ten element?");
    if (!confirmed) {
      return;
    }
    try {
      const response = await fetch(
        `https://power-on-gym-7kdm.vercel.app/api/delete/${classId}`,
        // `http://localhost:5000/api/delete/${classId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Błąd podczas usuwania elementu");
      }

      const result = await response.json();
      setclassesData(result.receivedData);
    } catch (error) {
      console.error("Błąd:", error);
      alert("Wystąpił błąd podczas usuwania elementu");
    }
  };

  return (
    <Box>
      <Box>
        <Box
          component="form"
          onSubmit={handleSubmit(uploadData)}
          noValidate
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          gap={4}>
          <Box
            sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
            noValidate
            autoComplete="off"
            display={"flex"}
            justifyContent={"center"}
            flexDirection={{ xs: "column", md: "row" }}>
            <TextField
              id="outlined-basic"
              label="Zajęcia"
              variant="outlined"
              {...register("title")}
              error={!!errors.title}
              helperText={errors.title?.message}
            />
            <TextField
              id="outlined-basic"
              label="Trener"
              variant="outlined"
              {...register("trener")}
              error={!!errors.trener}
              helperText={errors.trener?.message}
            />
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Dzień</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={day}
                  label="dzień"
                  {...register("date")}
                  error={!!errors.date}
                  onChange={(e) => setDay(e.target.value)}>
                  {daysOfWeek.map((day, index) => (
                    <MenuItem key={index} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Godzina</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={time}
                  label="Godzina"
                  {...register("time")}
                  error={!!errors.time}
                  onChange={(e) => setTime(e.target.value)}>
                  {hours.map((day, index) => (
                    <MenuItem key={index} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Button
            variant="contained"
            sx={{ backgroundColor: "black" }}
            type="submit">
            Dodaj
          </Button>
        </Box>
      </Box>

      <TimeTable
        handleDelete={handleDelete}
        setclassesData={setclassesData}
        classesData={classesData}
        hours={hours}
        daysOfWeek={daysOfWeek}
      />
    </Box>
  );
};

export default ConfigTimeTable;
