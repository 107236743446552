import "../../../styles/fitness.css";

function Fitness() {
  return (
    <div className="FitnessGallery">
      <div className="title">
        <p>Sala Fitness</p>
      </div>
      <div className="gallery">
        <div className="photo">
          <img
            src={process.env.PUBLIC_URL + "/images/fitness/fitness_1.jpg"}
            alt=""
          />
        </div>
        <div className="photo">
          <img
            src={process.env.PUBLIC_URL + "/images/fitness/fitness_2.jpg"}
            alt=""
          />
        </div>
        <div className="photo">
          <img
            src={process.env.PUBLIC_URL + "/images/fitness/fitness_3.jpg"}
            alt=""
          />
        </div>
        <div className="photo">
          <img
            src={process.env.PUBLIC_URL + "/images/fitness/fitness_4.jpg"}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Fitness;
