import React, { useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import Loader from "./Loader"; // Assuming this is a reusable loading component

import "../styles/App.css";
import LoaderWrapper from "./LoaderWrapper";
import About from "./routes/about";
import Main from "./routes/Main";
import Nav from "./nav";
import Footer from "./Footer";
import TimeTable from "./routes/time_table";
import ConfigPanel from "./ProtectedRoute/config_panel";
import VCursor from "./v-cursor";
import Tariff from "./routes/tariff";
import GymGallery from "./routes/SubRoutes/gym";
import PoleDance from "./routes/SubRoutes/poledance";
import Fitness from "./routes/SubRoutes/fitness";
import FitnessClasses from "./routes/SubRoutes/fitnessclasses";
import Contact from "./routes/Contact";
import Events from "./routes/events";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import { ContextProvider } from "./Context/ContextProvider";
import Offert from "./routes/Offert";
import MassageRouter from "./routes/massageRouter";

const App = () => {
  const hours = [
    "8:30",
    "9:00",
    "10:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
  ];
  const daysOfWeek = ["pon", "wt", "śr", "czw", "pt"];

  const [classesData, setclassesData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [loadingPercentage, setLoadingPercentage] = useState(0);

  return (
    <>
      <ContextProvider>
        <Router>
          <LoaderWrapper
            setIsLoading={setIsLoading}
            setLoadingPercentage={setLoadingPercentage}>
            <Nav isLoading={isLoading} />
            <Loader
              isLoading={isLoading}
              loadingPercentage={loadingPercentage}
            />
            <Routes>
              <Route path="/" element={<Main />} />
              <Route
                path="/timetable"
                element={
                  <TimeTable
                    classesData={classesData}
                    setclassesData={setclassesData}
                    hours={hours}
                    daysOfWeek={daysOfWeek}
                  />
                }
              />

              <Route path="/o-nas" element={<About />} />
              <Route path="/" element={<ProtectedRoute />}>
                <Route
                  path="/configpanel"
                  element={
                    <ConfigPanel
                      setclassesData={setclassesData}
                      classesData={classesData}
                      hours={hours}
                      daysOfWeek={daysOfWeek}
                    />
                  }
                />
              </Route>
              <Route
                path="/cennik"
                element={
                  <Tariff
                    setclassesData={setclassesData}
                    classesData={classesData}
                    hours={hours}
                    daysOfWeek={daysOfWeek}
                  />
                }
              />
              <Route path="/gym" element={<GymGallery />} />
              <Route path="/pole-dance" element={<PoleDance />} />
              <Route path="/fitness" element={<Fitness />} />
              <Route path="/zajecia-fitness" element={<FitnessClasses />} />
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/wydarzenia" element={<Events />} />
              <Route path="/oferta" element={<Offert />} />
              <Route path="masaż/*" element={<MassageRouter />} />
              <Route path="*" element={<Main />} />
            </Routes>
            <Footer />
            <VCursor />
          </LoaderWrapper>
        </Router>
      </ContextProvider>
    </>
  );
};

export default App;
