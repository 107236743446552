import React, { useState } from "react";

import { Box, Modal, Typography } from "@mui/material";

const trenersData = [
  {
    name: "Ada",
    lastName: "Kazibut",
    sub: "Trener personalny, Instruktor fitness",
    photo: "Ada.jpg",
    description:
      "Ada to uśmiechnięta i energiczna instruktorka oraz trenerka. Jej historia ze sportem była nieco zawiła, ale może być świetna motywacja dla innych. Od najmlodyszych lat nie dogadywała się ze sportem, zawsze na wf-ie była ostatnio osoba wybierana do gier zespołowych. Nie dostala się do klasy sportowej ze względu na swój wzrost.\n\nAda ma waleczna duszę i się nie poddała zaczęła uczęszczać na wszelkiego rodzaju SKS, zaczęła chodzic na zajęcia taneczne. Wylądowała na studiach wychowania fizycznego. I tak... \n\nAda została  licenjonowanym nauczycielem wychowania fizycznego, a jej przygoda z Fitnessem jako instruktor zaczęła się aż 12 lat temu. Od 12 lat intruktorka Zumby oraz Zumby Kids, Jr. Od 8 lat instruktorka fitness, aktualnie zajmuje się treningami personalnymi - kocha pracę z kobietami w ciąży i poporodzie. Licencjonowamy trener medyczny. Jest trenerem od zdań specjalnych, kontuzje i opercja klientów nie są jej straszne.  Przeszla dwa szkolenia z treningu personalnego oraz instruktora siłowni, szkoliła się dodatkowo z pracy z obciążeniem kettlebell, pracy na taśmach TRX.",
  },
  {
    name: "Konrad",
    lastName: "Oleszczuk",
    sub: "Trener personalny",
    photo: "Konrad.jpg",
    description:
      "Na codzień możecie mnie spotkać w Chojnie na siłowni Power On Gym. Swoją pracę początkowo jako recepcjonista zacząłem w październiku 2022r. Trenerem zostałem we wrześniu kolejnego roku, aby motywować innych do działania i zmieniać swoje życie na lepsze mając przy tym niezły fun. \n\nSpecjalizuje się w budowaniu siły na treningu jak i masy mięśniowej przy odpowiedniej diecie. Moją mocną stroną jest również kalistenika. Nie tylko z własną masą ciała, a także z dodatkowym ciężarem. Potrafię nauczyć Cię „latać” na drążku. Nawet jeśli nie możesz się podciągnąć ani razu. \n\nNa bieżąco poznaje nowe metody progresji w treningu, które sprzyjają moim podopiecznym co skutkuje nieustannym rozwojem. W wolnym czasie potrafię wsiąść w samochód i pojechać na „koniec świata”, ponieważ bardzo uszczęśliwiają mnie spontaniczne wyjazdy w nietuzinkowe miejsca.",
  },
  {
    name: "Dominika",
    lastName: "Olszańska",
    sub: "Trener personalny, Instruktor fitness",
    photo: "Dominika.jpg",
    description:
      "Od 12 roku życia jej pasją jest sport bez którego nie wyobraża sobie życia - musicie uważać żeby się nie zarazić. \n\nCiagle rozwijająca i szkoląca się Dominika poza siłownia uwielbia również gotować i robi to znakomicie czego mogliśmy doświadczyć. Doma (bo tak na nią mówimy ) prywatnie posiada kota Zosia z która powiemy wam szczerze dobrały sie idealnie bo to takie same zadziory - szukacie mistrzyni sarkazmu ? Proszę bardzo Dominika Olszańska do usług A tak naprawdę to bardzo ciepła i pełna energi osoba u której na treningu ( jeśli tylko chcecie ) możecie poczuć się jak na terapii u psychologa Dominika uwielbia pracować z ludźmi którzy chcą zmienić swoje życie na lepsze , bo wie ze razem możecie więcej . Jej główny cel dla osób z którymi współpracuje to zdrowa głowa w zdrowym i gotowym do wyzwań codzienności ciele",
  },
  {
    name: "Ania",
    lastName: "Kaczmarek",
    sub: "Ditetyk, Trener personalny, Instruktor fitness",
    photo: "Ania.jpg",
    description:
      "na social mediach znajdziecie ją pod nazwa trener_ze_smakiem. Skąd taka nazwa? Ania jest z wykształcenia dietetyczka a także ukończyła szkolenia na trenera personalnego oraz instruktora fitness Jak to mówi, urodziła się po to żeby się ruszać i nie wyobraża siebie życia bez aktywności fizycznej dlatego tez w życiu zawodowym poszła w tym kierunku i zachęca innych do prowadzenia aktywnego trybu życia. \n\nPrywatnie Ania jest psia mama włochatego Shih tzu o imieniu Kokos z którym codziennie nabija kroki a w sezonie pływa z nim na SUPie W końcu jaki właściciel taki psiak, obydwoje czerpią radości ze sportu W Power on możecie się z Ania zobaczyć na treningu personalnym lub na zajęciach grupowych, Ania ułoży Wam tez dietę, dzięki której zgubicie zbędne kilogramy Jeśli chcecie się z nią umówić to piszcie do niej śmiało na fb lub insta",
  },
  {
    name: "Tomasz",
    lastName: "Bilski",
    sub: "Trener personalny, Instruktor siłowni",
    photo: "Tomek.jpg",
    description:
      "zapalony sportowiec, podróżnik, fotograf krajobrazu i przyrody. Ukończył kurs trenera personalnego i instruktora siłowni na Akademii Mistrzostwa Sportowego w Poznaniu,pod okiem kadry byłych zawodników sportów sylwetkowych. \n\nNa co dzień możecie się z nim spotkać w siłowni POWER ON GYM,gdzie pomaga innym osiągnąć założony cel: schudnąć, wymodelować sylwetkę czy zbudować i utrzymać wymarzoną formę fizyczną. Doskonale potrafi zdiagnozować potrzeby i możliwości uczestnika treningu. Tomasz potrafi świetnie radzić sobie ze stresem podopiecznego, zna zasady prawidłowej komunikacji i efektywnie przekazuje komunikaty słowne i niewerbalne, a także właściwie odbiera sygnały od podopiecznego. Posiada wiedzę z zakresu żywienia,znajomość różnych rodzajów ćwiczeń i sprzętu,umiejętności motywacyjne, potrafi dopasować treningi do potrzeb swoich klientów. \n\nNa co dzień ojciec dwójki dzieci. Osoba z dużym poczuciem humoru,z ogromnym dystansem do siebie i otaczającego go świata. W wolnych chwilach pokonuje kilometry rowerem-gdyby je zliczyć - przejechałby ziemię nie jeden raz Empatyczny, sumienny, pracowity,   zdyscyplinowany, komunikatywny… można by tak bez końca. Potrzebujesz dobrego trenera? Nic prostrzego! Tomasza znajdziesz na social mediach.",
  },
  {
    name: "Diana",
    lastName: "Atłas",
    sub: "Instruktor fitness",
    photo: "Diana.jpg",
    description:
      "od 19 lat związana z fitnessem. Absolwentka PFI School. Posiada liczne szkolenia z zakresu zajęć ruchowych. Począwszy od klasycznych zajęć fitness aż po specjalistyczne zajęcia prozdrowotne. Zwolenniczka zajęć typu HI Impact. Lubi aktywnie spędzać czas wolny ....bieganie, siłownia, boks. Od najmłodszych lat miłośniczka koni i jeździectwa. Na co dzień pracuje w szkole z dziećmi prowadząc zajęcia z gimnastyki korekcyjnej.",
  },
  {
    name: "Paula",
    lastName: "Romuk",
    sub: "Trener personalny",
    photo: "Paula.jpg",
    description:
      "Paula to trenerka personalna, która z pasją i uśmiechem podchodzi do każdego dnia na siłowni. Już od dwóch lat jej nieodtącznym elementem życia są ciężary, a na siłowni można ją spotkać od poniedziałku do soboty, od rana do nocy - nawet w święta! \n\nMimo tego intensywnego grafiku, Paula znajduje czas na podróże, spotkania ze znajomymi, odpoczynek i chwile tylko dla siebie. Uwielbia poznawać swoje wnętrze i ma ogromny dystans do własnej osoby. Jest szczera, czasem aż do bólu, ale wiaśnie to sprawia, że jest tak autentyczna! \n\nPaula specjalizuje się w pracy z osobami z nadwagą, pomagając im zgubić kilogramy i walczyć o ich zdrowie. Nic nie sprawia je większej radości niż widok osiąganych wspólnie rezultatów. Jej misja jest, aby każdy mógł z nią ćwiczyć, dlatego potrafi zawsze spersonalizować oferte tak, aby nikt nie został bez wsparcia. \n\nUwielbia również pracować z osobami, którym brakuje pewności siebie. A w szczegóIności z kobietami Ponieważ jak mówi: “My kobiety, często zapominamy o sobie - dbamy o rodziny i przyjaciół, a siebie spychamy gdzieś tam na dalszy plan i Pamiętaj, że z mocnym JA, stajesz się śilniejsza | bardziej gotowa do dawania miłości i wsparcia innym!” \n\nNa siłowni bywa nazywana “terrorystka żmija, ale to tylko pierwsze wrażenie! Ci, którzy ją poznają bliżej, nie wyobrazają sobie bez jej treningów życia dłużej niż klka dni \n\nPaula to trenerka z sercem na dłoni, pełna energii i gotowa na każde wyzwanie. Jej celem jest nie tylko fizyczna przemiana, ale także wzmocnienie ducha i pewności siebie jej podopiecznych. Dołącz do niej na siłowni i przekonaj się, jak wspaniale mogą być efekty wspólnej pracy!",
  },
  {
    name: "Paweł",
    lastName: "Kazibut",
    sub: "Trener personalny",
    photo: "Pawel.jpg",
    description:
      "Paweł od zawsze związany ze sportem. Absolwent Akademii Wychowania Fizycznego we Wrocławiu. Instruktor pływania, instruktor latania Flyspot, trener personalny i „siłowniana” złota rączka. Prywatnie ojciec, mąż, brat, szwagier, wujek, syn i wnuk. Idealista, który zawsze patrzy pozytywnie w przód.",
  },
];

const AboutTeam = () => {
  const [selectedTrainer, setSelectedTrainer] = useState(null); // Stan wybranego trenera
  const [isModalOpen, setModalOpen] = useState(false); // Stan modalu

  const handleOpenModal = (trainer) => {
    setSelectedTrainer(trainer);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedTrainer(null);
    setModalOpen(false);
  };

  return (
    <Box sx={{ margin: "150px 0" }}>
      <p className="about-text">Nasi trenerzy</p>
      <Box>
        <Box
          className="slider-container"
          sx={{
            margin: "0 auto",
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
            },
            width: "80%",
            gap: 3,
          }}>
          {trenersData.map((element, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                boxSizing: "border-box",
                padding: 1,
                margin: "50px 0 0",
                cursor: "pointer",
              }}
              onClick={() => handleOpenModal(element)}>
              <img
                src={"/images/trainers/" + element.photo}
                alt={element.name + " " + element.lastName}
                width={"100%"}
                style={{ borderRadius: "10%" }}
              />
              <p style={{ marginTop: "20px", fontSize: "2rem" }}>
                {element.name}
              </p>
            </Box>
          ))}
        </Box>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box
          sx={{
            backgroundColor: "white",
            padding: 1,
            borderRadius: 2,
            maxWidth: "900px",
            width: "100%",
            boxShadow: 24,
            textAlign: "center",
          }}>
          {selectedTrainer && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "center", sm: "flex-start" },
                gap: 3,
                maxHeight: "90vh",
                overflow: "auto",
              }}>
              {/* Zdjęcie */}
              <Box sx={{ flexShrink: 0, textAlign: "center" }}>
                <img
                  src={"/images/trainers/" + selectedTrainer.photo}
                  alt={selectedTrainer.name + " " + selectedTrainer.lastName}
                  style={{
                    width: "150px",
                    borderRadius: "10%",
                    maxWidth: "100%",
                  }}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  textAlign: { xs: "center", sm: "left" },
                  padding: 3,
                }}>
                <Typography
                  variant="h4"
                  sx={{
                    marginBottom: 2,
                  }}>
                  {selectedTrainer.name} {selectedTrainer.lastName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ textAlign: { xs: "center", sm: "justify" } }}>
                  {selectedTrainer.description}
                </Typography>
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  padding: "10px 20px",
                  backgroundColor: "#007BFF",
                  color: "white",
                  borderRadius: "5px",
                  display: "inline-block",
                }}
                onClick={handleCloseModal}>
                Zamknij
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default AboutTeam;
