import "../../../styles/layout_gallery.css";
import { useNavigate } from "react-router-dom";

function LayoutGallery() {
  const navigate = useNavigate();

  return (
    <div className="layout-gallery">
      <div>
        <div className="title">
          <p>
            Siłownia i <br /> fitness
          </p>
        </div>
        <div className="context">
          Witamy w naszej siłowni. Znajdziesz u nas szerokiej gamy wysokiej
          jakości sprzęt, który spełni twoje oczekiwania treningowe, dużą salę
          fitness oraz salę pole dance.
        </div>
        <div className="photos">
          <div className="Photo gym">
            <img
              src={process.env.PUBLIC_URL + "/images/gym_0.jpg"}
              alt=""
              className="Hover"
              onClick={() => navigate("/gym")}
            />
            <p>Sala siłowni</p>
          </div>
          <div className="Photo poolDance">
            <img
              src={process.env.PUBLIC_URL + "/images/poolDance_0.jpg"}
              alt=""
              className="Hover"
              onClick={() => navigate("/pole-dance")}
            />
            <p>Sala pole dance</p>
          </div>
          <div className="Photo fitness">
            <img
              src={process.env.PUBLIC_URL + "/images/fitness_0.jpg"}
              alt=""
              className="Hover"
              onClick={() => navigate("/fitness")}
            />
            <p>Sala fitness </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutGallery;
