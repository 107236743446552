import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

import "../../../styles/schedule_responsive.css";

const ScheduleResponsive = (props) => {
  const { handleDelete, data, locationPath, daysOfWeek } = props;
  const [openDays, setOpenDays] = useState({});

  const toggleDay = (day) => {
    setOpenDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  const renderClassesForDay = (day) => {
    const classesForDay = data
      .filter((cls) => cls.date === day)
      .sort((a, b) => a.time.localeCompare(b.time)); // Sortowanie godzinami
    return classesForDay.map((cls) => (
      <div
        key={cls.id}
        className="classItem"
        onClick={
          locationPath === "/configpanel" ? () => handleDelete(cls._id) : null
        }>
        <div className="time">{cls.time}</div>
        <div className="info">
          <strong>{cls.title}</strong>
          <br />
          <span>Trener: {cls.trener}</span>
        </div>
      </div>
    ));
  };

  return (
    <div className="accordionSchedule">
      {daysOfWeek.map((day, index) => (
        <div key={index} className="accordionItem">
          <motion.div
            transition={{ duration: 0.5 }}
            className="accordionHeader"
            onClick={() => toggleDay(day)}>
            {day.toUpperCase()}
          </motion.div>
          <AnimatePresence initial={false}>
            {openDays[day] && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ opacity: 0, height: 0, transition: { duration: 0.3 } }}
                transition={{ duration: 0.5 }}
                className="accordionContent"
                style={{
                  overflow: "hidden",
                  display: openDays[day] ? "block" : "none",
                }}>
                {renderClassesForDay(day)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default ScheduleResponsive;
