import "../styles/loader.css";

const Loader = ({ isLoading, loadingPercentage }) => {
  return (
    isLoading && (
      <div className="loader">
        <div>Ładowanie: {loadingPercentage}%</div>
        <div></div>
      </div>
    )
  );
};

export default Loader;
