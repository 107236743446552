import "../../../styles/gym.css";

function GymGallery() {
  return (
    <div className="GymGallery">
      <div className="title">
        <p>Siłownia</p>
      </div>
      <div className="gallery">
        <div className="photo">
          <img src={process.env.PUBLIC_URL + "/images/gym/gym_1.jpg"} alt="" />
        </div>
        {/*<div className="photo">
          <img src={process.env.PUBLIC_URL + "/images/gym/gym_2.jpg"} alt="" />
        </div>*/}
        <div className="photo">
          <img src={process.env.PUBLIC_URL + "/images/gym/gym_3.jpg"} alt="" />
        </div>
        <div className="photo">
          <img src={process.env.PUBLIC_URL + "/images/gym/gym_4.jpg"} alt="" />
        </div>
        <div className="photo">
          <img src={process.env.PUBLIC_URL + "/images/gym/gym_5.jpg"} alt="" />
        </div>
        <div className="photo">
          <img src={process.env.PUBLIC_URL + "/images/gym/gym_6.jpg"} alt="" />
        </div>
      </div>
    </div>
  );
}

export default GymGallery;
