import "../../../styles/header.css";
import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

const textData = [
  "Jest siedem dni w tygodniu i „kiedyś” nie jest jednym z nich.",
  "Czasami rzeczy stają się możliwe, jeśli chcesz tego wystarczająco mocno.",
  "Szanuj swoje ciało. Odżywiaj swoje ciało. Wyzywaj swoje ciało na pojedynek. Ruszaj swoim ciałem. I najważniejsze: kochaj swoje ciało.",
  "Nigdy się nie poddawaj. Na wielkie rzeczy potrzeba czasu.",
  "Jest tylko jeden sposób, aby osiągnąć sukces praktycznie we wszystkim: dać z siebie wszystko.",
  "Wszystko jest możliwe, o ile masz wystarczający tupet.",
  "Każdy gdzieś zaczynał.",
  "Nie zaniżaj swoich sportowych celów do swoich możliwości. Zamiast tego podwyższaj swoje możliwości do swoich celów.",
  "Trudno jest pokonać osobę, która nigdy się nie poddaje.",
  "Kiedy masz jeden z tych dni, w czasie których czujesz się, jakbyś miał jakiś ciężar na barkach…. zacznij robić przysiady.",
  "Wątpliwości zabiły więcej marzeń niż jakiekolwiek porażki.",
  "Przegrywa nie ten który padł, lecz ten który nie chciał powstać.",
  "Sukces to suma niewielkiego wysiłku powtarzanego z dnia na dzień.",
  "Gdy na twojej drodze pojawi się wiele przeszkód, nie pozwól byś ty sam był jedną z nich.",
  "Zanim coś osiągniesz, musisz czegoś od siebie oczekiwać.",
  "Zwycięzcą jest ten, który podnosi się wtedy kiedy już nie może.",
];

function Header() {
  const [text, setText] = useState(textData[0]);
  const i = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      i.current++;
      if (i.current === textData.length) {
        i.current = 0;
      }
      setText(textData[i.current]);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="header">
      <video autoPlay muted loop playsInline>
        <source src="/img/headerVideo.mov" type="video/mp4" />
      </video>
      <div className="title">
        <img src="/images/HeaderLogo.png" alt="" />
      </div>
      <div className="content">
        <motion.p
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}>
          {text}
        </motion.p>
      </div>
    </div>
  );
}

export default Header;
