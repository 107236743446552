import { Typography, Box } from "@mui/material";

const AsiaPage = () => {
  return (
    <Box
      sx={{
        padding: "20px",
        marginTop: "100px",
        display: "flex",
        flexDirection: { xs: "column-reverse", md: "row" },
        justifyContent: { xs: "center", md: "space-between" },
      }}>
      <Box sx={{ width: { xs: "100%", md: "70%" } }}>
        <Typography
          variant="h2"
          marginTop="50px"
          marginBottom={"50px"}
          fontWeight={400}
          fontSize={{ xs: "30px", md: "50px" }}>
          Joanna Werpachowska - Fizjoterapeutka, masażystka, specjalistka masażu
          kobido.
        </Typography>
        <Typography variant="body1" marginTop="10px" fontSize={"20px"}>
          Specjalizuje się w fizjoterapii estetycznej i fizjoterapii
          stomatologicznej, naturalnych metodach odmładzania i holistycznej
          pielęgnacji zdrowia, manualnej terapii twarzy i ciała, masażu kobido
          oraz zabiegach wellness & spa.
        </Typography>
        <Typography
          variant="h3"
          marginTop="50px"
          marginBottom={"50px"}
          borderBottom={"1px solid black"}
          paddingBottom={"10px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          NATURALNE METODY ODMŁADZANIA FIZJOTERAPIA ESTETYCZNA - TWARZ
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Masaż Kobido
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Masaż twarzy, który nie tylko odpręża, ale działa liftingująco.
          Regularne sesje poprawiają elastyczność skóry, zmniejszają zmarszczki
          i stymulują produkcję kolagenu.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          kobido 1h - 200 zł
          <br />
          kobido - 1,5 h - 250 zł
          <br />
          kobido 1 h + hydrojelly - 250 zł
          <br />
          kobido - 1,5 h + hydrojelly - 300 zł
          <br />
          kobido 1 h + rytuał esthemax - 290 zł
          <br />
          kobido 1,5 h + rytuał esthemax - 340 zł
          <br />
          Autorski rytuał liftingujący ESTHEMAX PROFESSIONAL z maską Contouring
          2 h - 390 zł
        </Typography>
        <Typography
          variant="h3"
          marginTop="50px"
          marginBottom={"50px"}
          borderBottom={"1px solid black"}
          fontSize={{ xs: "30px", md: "50px" }}
          paddingBottom={"10px"}>
          RYTUAŁY WELLNESS&SPA
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Masaż gorącymi kamieniami
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Ciepło kamieni bazaltowych przenika głęboko w tkanki, rozluźniając
          mięśnie i poprawiając krążenie. Idealny dla osób, które pragną
          głębokiego odprężenia i relaksu.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          250 zł / 1h
          <br />
          290 zł/1,5 h
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Masaż aromatyczną świecą
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          To połączenie masażu z aromaterapią i pielęgnacją skóry. Ciepły olejek
          pochodzący z topniejącej świecy nawilża i odżywia skórę, a delikatny
          masaż wprowadza ciało i umysł w stan pełnego odprężenia.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          250 zł / 1h
          <br />
          290 zł/1,5 h
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Masaż relaksacyjny czarna orchidea
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Połączenie masażu z aromaterapią i pielęgnacją skóry. Ciepłe masło
          shea głęboko nawilża i odżywia skórę, a delikatny masaż wprowadza
          ciało i umysł w stan pełnego odprężenia.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          250 zł / 1h
          <br />
          290 zł/1,5 h
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Rytuał czarna orchidea
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Orzeźwiający peeling całego ciała w połączeniu relaksacyjnego masażu
          całego ciała na bazie odżywczego masła shea. Zabieg głęboko
          nawilżający! Wprowadza Ciało i umysł w stan pełnego odprężenia!
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          250 zł / 1h
          <br />
          350 zł / 2 h
        </Typography>
        <Typography
          variant="h3"
          marginTop="50px"
          marginBottom={"50px"}
          borderBottom={"1px solid black"}
          paddingBottom={"10px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          FIZJOTERAPIA STOMATOLOGICZNA
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Wizyta terapeutyczna
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Terapia manualna stawów skroniowo-żuchwowych + ćwiczenia techniki
          dobrane indywidualnie w celu załagodzenia dolegliwości bólowych.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          230 zł / 1h
          <br />
          PAKIET 3 wizyt: 600 zł
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Masaż głowy
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Głowa to nie tylko skalp. To również potylica,twarz z wszystkimi jej
          elementami – nosem, oczodołami, czołem, policzkami i wargami. Masaż
          kompleksowo obejmuje twarz a także stanowiące podporę głowy mięśnie
          szyi, karku i ramion. Z elementami japońskiej terapii manualnej
          SHIATSU, polegającej na uciskaniu kciukami punktów akupresurowych.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          250 zł/1h
        </Typography>
        <Typography
          variant="h3"
          marginTop="50px"
          marginBottom={"50px"}
          borderBottom={"1px solid black"}
          fontSize={{ xs: "30px", md: "50px" }}
          paddingBottom={"10px"}>
          FIZJOTERAPIA ESTETYCZNA CIAŁA
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Masaż modelujący bańką chińską <br />
          (uda przód, tył, pośladki)
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Absolutny hit w walce z cellulitem, skóra za pomocą podciśnienia jest
          zasysana do specjalnych, gumowych baniek. Dzięki energicznym ruchom,
          dochodzi do miejscowego przekrwienia skóry, która staje się bardziej
          dotleniona i odżywiona. Jednocześnie, cellulit jest rozbijany.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          230 zł/1h
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Terapia blizn
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Terapia blizn to specjalistyczne podejście do leczenia i poprawy
          wyglądu oraz funkcjonalności tkanek po urazach, operacjach lub
          chorobach skóry, cesarskim cięciu. Obejmuje ona różnorodne techniki,
          takie jak masaż, mobilizacja tkanek miękkich, kinesiotaping
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Cena od 180 zł/1h
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Manualny masaż ujędrniający
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Masaż ujędrniający ma na celu zwiększyć jędrność i uelastycznienie
          skóry. Bardzo często stosowany jest po gwałtownym schudnięciu, kiedy
          widoczny jest nadmiar wiotkiej i opadającej skóry. Taki rodzaj masażu
          dobrze poprawia krążenie, napina skórę poprzez wzmocnienie włókien
          kolagenowych, poprawia koloryt skóry i sprawia, że widoczny jest efekt
          liftingu.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          250 zł / 1h
          <br />
          290 zł / 1,5h
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          kobido
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Masaż twarzy, który nie tylko odpręża, ale działa liftingująco.
          Regularne sesje poprawiają elastyczność skóry, zmniejszają zmarszczki
          i stymulują produkcję kolagenu.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          kobido 1h - 200 zł
          <br />
          kobido - 1,5 h - 250 zł
          <br />
          PAKIET 10 wizyt - 1800 zł zamiast 2000 zł
        </Typography>
        <Typography
          variant="h3"
          marginTop="50px"
          marginBottom={"50px"}
          borderBottom={"1px solid black"}
          fontSize={{ xs: "30px", md: "50px" }}
          paddingBottom={"10px"}>
          MASAŻE SPECJALISTYCZNE
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          Masaż leczniczy
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Od innych typów masażu odróżnia go to, że służy przede wszystkim
          leczeniu chorób, bólu oraz innych dolegliwości. Ale co ważne, oprócz
          oddziaływania czysto medycznego, wpływa na organizm również
          odprężająco i uspakajająco.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          100 zł/30 min
          <br />
          180 zł / 1 h
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          masaż sportowy
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Masaż sportowy przyśpiesza regenerację mięśni, ścięgien i więzadeł,
          zmniejsza napięcie mięśniowe oraz zwiększa zakres ruchu, minimalizując
          ryzyko doznania kontuzji i zwiększając wydajność sportowca.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          100 zł/30 min
          <br />
          180 zł / 1 h
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          masaż dla kobiet w ciąży
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Masaż dla kobiet w ciąży skutecznie pomaga zlikwidować dolegliwości
          bólowe pleców i nóg, zmniejsza obrzęk stóp, zapobiega żylakom oraz
          poprawia ogólną kondycję fizyczną, oraz psychiczną kobiety. Zdrowie i
          komfort psychiczny młodej mamy są niezwykle ważne podczas całego
          okresu ciąży.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          100 zł/30 min
          <br />
          180 zł / 1 h
        </Typography>
        <Typography
          variant="h4"
          marginTop="50px"
          marginBottom={"20px"}
          fontSize={{ xs: "30px", md: "50px" }}>
          kinesiotaping
        </Typography>
        <Typography variant="body1" marginTop="10px" fontSize={"20px"}>
          Plastrowanie dynamiczne, dzięki zwiększeniu przestrzeni między skórą a
          mięśniami, ma działanie przeciwbólowe, użycie taśm kinesio powoduje
          rozluźnienie mięśni i zmniejszenie ich napięcia. Ponieważ plastry
          stabilizują stawy, znajdują zastosowanie podczas uprawiania sportu –
          zapobiegają powstawaniu kontuzji.
        </Typography>
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          Od 50 zł
        </Typography>
      </Box>
      <Box
        sx={{
          width: { xs: "60%", md: "20%" },
          margin: { xs: "0 auto", md: "0" },
        }}>
        <img
          src="/images/Massage/Asia.jpeg"
          alt="Kasia"
          style={{
            width: "100%",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
        <Typography variant="body1" fontSize={"20px"} marginTop="10px">
          FizjoBalance&Beauty Joanna Werpachowska <br />
          Tel: 691 187 693
        </Typography>
      </Box>
    </Box>
  );
};

export default AsiaPage;
