import { BrowserRouter as Routes, Route } from "react-router-dom";
import AsiaPage from "./SubRoutes/AsiaPage";
import KasiaPage from "./SubRoutes/KasiaPage";
import Massage from "./SubRoutes/Massage";

const MassageRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Massage />} />
      <Route path="/*" element={<Massage />} />
      <Route path="/Asia" element={<AsiaPage />} />
      <Route path="/Kasia" element={<KasiaPage />} />
    </Routes>
  );
};

export default MassageRouter;
