import React, { useEffect, createRef } from "react";
import { useLocation } from "react-router-dom";
import "../../styles/about.css";

import AboutTeam from "./SubRoutes/aboutTeam";

const About = () => {
  const { state } = useLocation();
  const { targetId } = state || {};

  const bg = createRef();

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  return (
    <div className="about" ref={bg}>
      <div className="about-one">
        <div className="content">
          <p className="about-text">O nas</p>
          <p className="title">
            Witamy w Power On Gym – Twoim partnerze w drodze do zdrowia i
            lepszej kondycji!
          </p>
        </div>
        <div className="photo">
          <img src="/images/layout_fitness.jpg" alt="" />
        </div>
      </div>
      <div className="about-two">
        <p className="about-text">Recepcja</p>
        <div className="photos">
          <div className="photo">
            <img src="/images/recepcja/recepcja2.jpg" alt="" />
          </div>
        </div>
      </div>
      <div className="about-three">
        <div className="content">
          <p className="about-text title">Kim jesteśmy</p>
          <div className="conetent-text">
            <p>
              Power On Gym to miejsce, gdzie pasja do zdrowego stylu życia
              spotyka się z profesjonalizmem, a to wszystko w atmosferze pełnej
              dobrego humoru i dystansu do siebie. Nasza misja to inspirowanie i
              wspieranie ludzi w osiąganiu ich celów zdrowotnych i fitnessowych
              w radosny i pozytywny sposób. Pamiętaj, że wysportowana sylwetka
              to proces do którego musimy mieć cierpliwość ale również dystans.
              Dołącz do nas i poza celami czysto fizycznymi zdobądź nowe pokłady
              energii, zdrowia i lepsze samopoczucie co poprawi jakość Twojego
              życia. Pamiętaj nie każdy musi być kulturystą… przyjdź, zostań z
              nami i wspólnie zadbajmy o nasze dobre samopoczucie.
            </p>
            <p>
              W naszym klubie znajdziecie wielu cudownych ludzi, którzy chętnie
              pomogą Wam w najróżniejszy sposób od masażystów, przez recepcję,
              instruktorów fitness, trenerów, dietetyka, animatorów. Zadbamy o
              was pod wieloma względami. Poniżej zapraszamy do zapoznania się z
              zespołem Power On Gym
            </p>
          </div>
        </div>
      </div>
      <AboutTeam />
    </div>
  );
};

export default About;
