import React, { useState, useEffect, useCallback } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import "../../../styles/layout_fitness.css";

function FitnessClasses() {
  const navigate = useNavigate();
  const [gapSize, setGapSize] = useState(60);
  const [scaleSize, setScaleSize] = useState(0.5);

  const StickyAnim = useCallback(() => {
    const layoutElement = document.querySelector(".layout_fitnessClasses");
    const positiontop = layoutElement.offsetTop;
    const positionBottom = layoutElement.offsetHeight + positiontop;
    const scrollY = window.scrollY;

    if (scrollY >= positiontop && scrollY < positionBottom) {
      const newGapSize =
        60 - ((scrollY - positiontop) / (positionBottom - positiontop)) * 90;
      const newScaleSize = 0.5 + ((scrollY - positiontop) / positionBottom) * 2;
      if (newGapSize !== gapSize)
        setGapSize(Math.min(60, Math.max(2, newGapSize)));
      if (newScaleSize !== scaleSize)
        setScaleSize(Math.max(0.5, Math.min(1.0, newScaleSize)));
    }
  }, [gapSize, scaleSize]);

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(StickyAnim);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [StickyAnim]);

  return (
    <div className="layout_fitnessClasses">
      <div className="sticky">
        <div
          className="image Hover"
          onClick={() => navigate("/zajecia-fitness")}>
          <img
            src={process.env.PUBLIC_URL + "/images/layout_fitness.jpg"}
            style={{ scale: `${scaleSize}` }}
            alt="Fitness layout"
          />
        </div>
        <div
          className="content Hover"
          onClick={() => navigate("/zajecia-fitness")}
          style={{
            gap: `${Math.min(60, Math.max(2, gapSize))}%`,
          }}>
          <p className="title first Hover">Zajęcia</p>
          <p className="title second Hover">Fitness</p>
        </div>
        <div>
          <Marquee className="marque one" speed={80}>
            <p>
              &nbsp;zumba - brazylijskie pośladki - Mocny brzuch - stretching -
              body pumb - tabata - mocny brzuch + streatch - pole dance -
            </p>
          </Marquee>
          <Marquee className="marque two" speed={50} direction="right">
            <p>
              &nbsp;cross - step - pilates + joga - trening obwodowy - aerobox -
              body Ball - zdrowy kręgosłup -
            </p>
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default FitnessClasses;
