import React from "react";

import "../../../styles/fitnessclasses.css";

const fitnessData = [
  {
    title: "Brazylijskie pośladki",
    description:
      "to kompleksowy trening w całości skoncentrowany na dolnych partiach ciała, który umożliwia ich wymodelowanie, wzmocnienie i ujędrnienie. Służą temu ćwiczenia znane z treningu funkcjonalnego, np. przysiady i wykroki.",
  },
  {
    title: "Mocny Brzuch",
    description:
      "mają na celu zbudowanie silnych mięśni brzucha, bioder oraz lędźwi, które odpowiedzialne są za stabilizację kręgosłupa i wzmocnienie całej sylwetki. W treningu brzucha mocny nacisk kładziony jest na kolejności wykonywania ćwiczeń, odpowiedniej częstotliwości oraz ilości powtórzeń. ",
  },
  {
    title: "Stretching",
    description:
      "(czyli rozciąganie) są to zajęcia oddechowe, rozluźniające i rozciągające przy relaksującej muzyce. Wykorzystuje się w nich proste asany z klasycznej jogi i elementy pilatesu. Trener proponuje zwykle – w zależności od kondycji i zaawansowania grupy – trzy wersje każdego ćwiczenia: łatwą, średnią i trudną. Idealne zajęcia jeżeli zaczynasz swoją przygodę z zajęciami fitness",
  },
  {
    title: "STEP",
    description:
      "ćwiczenia w rytm tanecznej muzyki polegają na dynamicznym wchodzeniu i schodzeniu ze stopnia na różne sposoby. Podczas jednych zajęć step aerobiku można spalić nawet 600 kcal, a ponadto wyrzeźbić i ujędrnić uda, pośladki oraz łydki.",
  },
  {
    title: "PILATES +YOGA",
    description:
      "Jest to rodzaj treningu wykonywanego w umiarkowanej intensywności przy jednoczesnej kontroli oddechu oraz koncentracji na dokładności wykonywanych ruchów. Wszystkie ruchy wykonywane są w sposób płynny, co umożliwia dodatkowo zwiększenie kontroli oraz świadomości własnego ciała.",
  },
  {
    title: "Trening obwodowy",
    description:
      "to połączenie różnych ćwiczeń, np. o charakterze siłowym, wytrzymałościowym (cardio) i rozciągającym na zasadzie charakterystycznych stacji. Na każdej z nich osoba trenująca musi wykonać inne ćwiczenie, dzięki czemu wzmacniamy całe ciało.",
  },
  {
    title: "Trening pump",
    description:
      "łączy ze sobą dynamiczne elementy fitnessu z treningiem siłowym. Podczas zajęć wykonywane są ćwiczenia wielostawowe, do których należą m.in. przysiady, czy chociażby wznosy sztangi nad głowę",
  },
  {
    title: "zdrowy kręgosłup",
    description:
      "to programy ćwiczeń, które koncentrują się na wzmocnieniu mięśni pleców, poprawie elastyczności kręgosłupa oraz zapobieganiu bólom pleców. Te zajęcia obejmują różnorodne formy aktywności, takie jak pilates, ćwiczenia rozciągające, a także elementy treningu siłowego.",
  },
  {
    title: "Tabata",
    description:
      "to trening interwałowy, który polega na wykonywaniu serii ćwiczeń (około 8 serii, każda po 30 sekund), po których następuje 10-sekundowa przerwa. Taka kompozycja treningu pozwala na podniesienie tempa metabolizmu na nawet po zakończeniu ćwiczeń.",
  },
  {
    title: "Zumba",
    description:
      "to interwałowa impreza fitnessowa, która łączy w sobie ruchy o niskiej i wysokiej intensywności . Spalisz mnóstwo kalorii, poruszając się w rytmie inspirowanych latynoską choreografią. Najlepsze w tym wszystkim jest to, że nawet nie poczujesz, że to ćwiczenia.",
  },
  {
    title: "mocny brzuch + stretch",
    description:
      "To prosty zestaw ćwiczeń rozciągających, modelujących i relaksacyjnych. Trening nastawiony jest głównie na efekt płaskiego brzucha i wysmuklenie okolic talii. Pierwszy etap to ćwiczenia modelujące, które angażują mięśnie brzucha. Drugi etap to ćwiczenia wzmacniające kręgosłup, czyli aktywizujące mięśnie pleców. Trzecia część treningu obejmuje ćwiczenia rozciągające.",
  },
  {
    title: "BodyBall",
    description:
      "to trening z piłką fitness, który wzmacnia mięśnie, poprawia równowagę i stabilizację. Ćwiczenia są intensywne, ale bezpieczne, angażują całe ciało i pomagają w rzeźbieniu sylwetki. Idealny wybór dla osób, które chcą połączyć efektywność z dobrą zabawą!",
  },
  {
    title: "AEROBOX",
    description:
      "Zajęcia będące kombinacją fitnessu i sztuk walki. Trening prowadzony w rytm motywacyjnej muzyki przy pomocy skutecznych technik wywodzących się głównie z boksu i kickboxingu oraz ćwiczeń funcjonalnych używanych często na treningach fighterskich, jak np. burpee, pompki.",
  },
  {
    title: "Cross",
    description:
      "to intensywny trening siłowy z wykorzystaniem maszyn, idealny dla osób chcących zwiększyć siłę i wytrzymałość. Pod okiem trenera wykonasz dynamiczne ćwiczenia angażujące całe ciało. Doskonały wybór dla tych, którzy szukają maksymalnych efektów w krótkim czasie!",
  },
];

function FitnessClasses() {
  return (
    <div className="fitnessClasses">
      <div>
        <div className="title">
          <p>Zajęcia fitness</p>
        </div>
        <div className="list">
          {fitnessData.map((item, index) => (
            <div className="item" key={index}>
              <p className="title">{item.title}</p>
              <p className="description">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FitnessClasses;
