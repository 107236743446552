import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "../styles/v-cursor.css";

const VCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const onMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });

      const elementUnderCursor = document.elementFromPoint(
        e.clientX,
        e.clientY
      );
      if (
        elementUnderCursor &&
        elementUnderCursor.classList.contains("Hover")
      ) {
        setIsHovering(true);
      } else {
        setIsHovering(false);
      }
    };

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  return (
    <motion.div
      className={`cursor ${isHovering ? "target_cursor" : ""}`}
      animate={{
        x: position.x - 50,
        y: position.y - 50,
        scale: isHovering ? 1.5 : 1,
      }}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 20,
      }}>
      {isHovering && <p>sprawdź</p>}
    </motion.div>
  );
};
export default VCursor;
