import React from "react";
import {
  AccordionSummary,
  AccordionDetails,
  Box,
  Accordion,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ConfigTimeTable from "./opctions/ConfigTimeTable";
import ConfigTariff from "./opctions/ConfigTariff";
import Register from "./Register";
import ConfigEvent from "./opctions/ConfigAddEvents";
import ConfigDeleteEvent from "./opctions/ConfigDeleteEvent";

import "../../styles/config_panel.css";

function ConfigPanel(props) {
  const { setclassesData, classesData, hours, daysOfWeek } = props;

  return (
    <div className="config_classes">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ backgroundColor: "#945a5a", color: "white" }}>
          <Box fontSize={40}>Grafik</Box>
        </AccordionSummary>
        <AccordionDetails>
          <ConfigTimeTable
            setclassesData={setclassesData}
            classesData={classesData}
            hours={hours}
            daysOfWeek={daysOfWeek}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ backgroundColor: "#945a5a", color: "white" }}>
          <Box fontSize={40}>Edytuj Cennik</Box>
        </AccordionSummary>
        <AccordionDetails>
          <ConfigTariff />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ backgroundColor: "#945a5a", color: "white" }}>
          <Box fontSize={40}>Dodaj wydarzenie</Box>
        </AccordionSummary>
        <AccordionDetails>
          <ConfigEvent />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ backgroundColor: "#945a5a", color: "white" }}>
          <Box fontSize={40}>Usuń wydarzenie</Box>
        </AccordionSummary>
        <AccordionDetails>
          <ConfigDeleteEvent />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ backgroundColor: "#945a5a", color: "white" }}>
          <Box fontSize={40}>Zarejestruj nowe konto</Box>
        </AccordionSummary>
        <AccordionDetails>
          <Register />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ConfigPanel;
