import React, { useEffect } from "react";

import Header from "./SubRoutes/header";

import LayoutGallery from "./SubRoutes/layout_gallery";
import LayoutShop from "./SubRoutes/layout_shop";
import FitnessClasses from "./SubRoutes/layout_fitness";

import "../../styles/main.css";

function Main() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main">
      <Header />
      <div className="mainContainer">
        <LayoutGallery />
        <FitnessClasses />
        <LayoutShop />
      </div>
    </div>
  );
}

export default Main;
