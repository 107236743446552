import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Massage = () => {
  const [isOverlayVisibleAsia, setIsOverlayVisibleAsia] = useState(false);
  const [isOverlayVisibleKasia, setIsOverlayVisibleKasia] = useState(false);
  const navigate = useNavigate();

  const handleImageClickAsia = () => {
    if (window.innerWidth <= 600) {
      setIsOverlayVisibleAsia((prev) => !prev);
    }
  };

  const handleImageClickKasia = () => {
    if (window.innerWidth <= 600) {
      setIsOverlayVisibleKasia((prev) => !prev);
    }
  };

  return (
    <Box
      sx={{ marginTop: "150px", marginBottom: "150px", height: "max-content" }}>
      <Typography
        variant="h2"
        gutterBottom
        marginTop={"50px"}
        marginBottom={"100px"}
        marginLeft={"20px"}>
        Oferty Masażu
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          justifyContent: "center",
          gap: "20px",
        }}>
        <Box
          sx={{
            width: { xs: "80%", md: "30%" },
            margin: { xs: "0 auto", md: "0" },
            position: "relative",
            overflow: "hidden",
            cursor: "pointer",
            "@media (max-width: 600px)": {
              "&:hover .overlay": {
                opacity: 0,
                visibility: "hidden",
              },
            },
            "&:hover .overlay": {
              opacity: 1,
              visibility: "visible",
            },
          }}
          onClick={handleImageClickAsia}>
          <img
            src="/images/Massage/Asia.jpeg"
            alt="Asia"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Typography
            variant="subtitle1"
            gutterBottom
            display={{ xs: "block", md: "none" }}
            textAlign={"center"}>
            Kliknij na zdjęcie
          </Typography>
          <Box
            className={`overlay ${isOverlayVisibleAsia ? "visible" : ""}`}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              opacity: 0,
              visibility: "hidden",
              transition: "opacity 0.3s ease, visibility 0.3s ease",
            }}>
            <Typography variant="h3" gutterBottom>
              Asia
            </Typography>
            <Typography variant="subtitle1" textAlign="center">
              Fizjoterapia Stomatologiczna, fizjoterapia estetyczna, kobido,
              manualne naturalne techniki odmładzania, terapie manualne twarzy i
              ciała!
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/masaż/Asia")}
              sx={{ marginTop: "30px" }}>
              Sprawdź
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            width: { xs: "80%", md: "30%" },
            margin: { xs: "0 auto", md: "0" },
            position: "relative",
            overflow: "hidden",
            cursor: "pointer",
            "@media (min-width: 600px)": {
              "&:hover .overlay": {
                opacity: 1,
                visibility: "visible",
              },
            },
            "&:hover .overlay": {
              opacity: 1,
              visibility: "visible",
            },
          }}
          onClick={handleImageClickKasia}>
          <img
            src="/images/Massage/Kasia.jpg"
            alt="Kasia"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Typography
            variant="subtitle1"
            gutterBottom
            display={{ xs: "block", md: "none" }}
            textAlign={"center"}>
            Kliknij na zdjęcie
          </Typography>
          <Box
            className={`overlay ${isOverlayVisibleKasia ? "visible" : ""}`}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              opacity: 0,
              visibility: "hidden",
              transition: "opacity 0.3s ease, visibility 0.3s ease",
            }}>
            <Typography variant="h3" gutterBottom>
              Kasia
            </Typography>
            <Typography variant="subtitle1" textAlign="center">
              Terapeuta biomasażu. Specjalizuje się w masażach orientalnych i
              ajurwedyjskich. Jej pasja do masażu pozwala łączyć tradycyjne
              techniki z nowoczesnym podejściem do zdrowia i relaksu.
            </Typography>
            <Button
              variant="contained"
              onClick={() => navigate("/masaż/Kasia")}
              sx={{ marginTop: "30px" }}>
              Sprawdź
            </Button>
          </Box>
        </Box>
      </Box>
      <div className="FitnessGallery">
        <Typography
          variant="h2"
          gutterBottom
          marginTop={"50px"}
          marginBottom={"100px"}
          marginLeft={"20px"}>
          Sala Masażu
        </Typography>
        <div className="gallery">
          <div className="photo">
            <img
              src={process.env.PUBLIC_URL + "/images/Massage/massage_1.jpg"}
              alt=""
            />
          </div>
          <div className="photo">
            <img
              src={process.env.PUBLIC_URL + "/images/Massage/massage_2.jpg"}
              alt=""
            />
          </div>
          <div className="photo">
            <img
              src={process.env.PUBLIC_URL + "/images/Massage/massage_3.jpg"}
              alt=""
            />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Massage;
