import { Box, Typography } from "@mui/material";

const KasiaPage = () => {
  return (
    <div className="massagePage">
      <Box
        sx={{
          padding: "20px",
          marginTop: "100px",
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          justifyContent: { xs: "center", md: "space-between" },
        }}>
        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
          <Typography
            variant={"h2"}
            fontSize={{ xs: "30px", md: "50px" }}
            fontWeight={400}
            marginTop="50px"
            marginBottom={"50px"}>
            Kasia Matuszewska - terapeuta biomasażu.
          </Typography>
          <Typography variant="body1" marginTop="10px" fontSize={"20px"}>
            Specjalizuje się w masażach orientalnych i ajurwedyjskich. Jej pasja
            do masażu pozwala łączyć tradycyjne techniki z nowoczesnym
            podejściem do zdrowia i relaksu.
          </Typography>
          <Typography
            variant="h3"
            marginTop="50px"
            marginBottom={"50px"}
            fontSize={{ xs: "30px", md: "50px" }}>
            W jej ofercie znajdziesz różnorodne masaże, które obejmują:
          </Typography>
          <Typography
            variant="h4"
            marginTop="10px"
            paddingBottom={"10px"}
            borderBottom={"1px solid black"}
            fontSize={{ xs: "30px", md: "50px" }}>
            Masaż klasyczny
          </Typography>
          <Typography variant="body1" marginTop="10px" fontSize={"20px"}>
            Dostępny w dwóch stopniach, który skupia się na ogólnym relaksie
            oraz rozluźnieniu mięśni.
          </Typography>
          <Typography
            variant="h4"
            marginTop="10px"
            paddingBottom={"10px"}
            borderBottom={"1px solid black"}
            fontSize={{ xs: "30px", md: "50px" }}>
            Masaż terapeutyczny
          </Typography>
          <Typography variant="body1" marginTop="10px" fontSize={"20px"}>
            Dedykowany osobom z dolegliwościami bólowymi, który przynosi ulgę i
            wspomaga proces rehabilitacji.
          </Typography>
          <Typography
            variant="h4"
            marginTop="10px"
            paddingBottom={"10px"}
            borderBottom={"1px solid black"}
            fontSize={{ xs: "30px", md: "50px" }}>
            Masaże orientalne
          </Typography>
          <Typography variant="body1" marginTop="10px" fontSize={"20px"}>
            Takie jak masaż lomi lomi, masaż chiński bambusowy czy masaż
            czekoladą, które harmonizują ciało i umysł, wykorzystując techniki
            zaczerpnięte z tradycyjnych praktyk Wschodu.
          </Typography>
          <Typography
            variant="h4"
            marginTop="10px"
            paddingBottom={"10px"}
            borderBottom={"1px solid black"}
            fontSize={{ xs: "30px", md: "50px" }}>
            Masaże spa&wellness
          </Typography>
          <Typography variant="body1" marginTop="10px" fontSize={"20px"}>
            Relaksacyjne rytuały, które łączą masaż z aromaterapią oraz innymi
            technikami ,aby zapewnić pełne odprężenie i regenerację.
          </Typography>
          <Typography
            variant="h4"
            marginTop="50px"
            marginBottom={"50px"}
            fontSize={{ xs: "30px", md: "50px" }}>
            Zapraszamy do skorzystania z usług, aby doświadczyć korzyści
            płynących z masażu i odnaleźć harmonię w codziennym życiu.
          </Typography>
          <Typography variant="body1" marginTop="0px" fontSize={"20px"}>
            Masaż klasyczny - 130/h
          </Typography>
          <Typography variant="body1" marginTop="20px" fontSize={"20px"}>
            Masaż terapeutyczny - 250/h
          </Typography>
          <Typography variant="body1" marginTop="20px" fontSize={"20px"}>
            Masaż czekoladą - 220zł/h
          </Typography>
          <Typography variant="body1" marginTop="20px" fontSize={"20px"}>
            Masaż chiński bambusowy - 200zł/h
          </Typography>
          <Typography variant="body1" marginTop="20px" fontSize={"20px"}>
            Masaż balijski - 230zł/h, 270zł/90min
          </Typography>
          <Typography variant="body1" marginTop="20px" fontSize={"20px"}>
            Masaż miodowy - 150zł/h, 90zł/30min(wybrana partia ciała)
          </Typography>
          <Typography variant="body1" marginTop="20px" fontSize={"20px"}>
            Masaż sportowy - 180zł/h
          </Typography>
          <Typography variant="body1" marginTop="20px" fontSize={"20px"}>
            Masaż lomi lomi - 300zł/90min
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: "60%", md: "20%" },
            margin: { xs: "0 auto", md: "0" },
          }}>
          <img
            src="/images/Massage/Kasia.jpg"
            alt="Kasia"
            style={{
              width: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

export default KasiaPage;
